import A11yDialog from 'a11y-dialog'
const Handlebars = require('handlebars');

let tourTemplate = {};
let bookingTemplate = {};
let availableSlots = [];
let availableDates = [];

const addBodyClass = function(el) {
    el.on('show', function (element, event) {
        $('body').addClass('__modal')
        // Do something on show
    })
    el.on('hide', function (element, event) {
        $('body').removeClass('__modal')
        // Do something on hide
    })
}

const handleShowModal = function() {
    const pageLoadModal = document.querySelector('.__showmodal');

    if (pageLoadModal != null) {
        const modalId = pageLoadModal.getAttribute('data-form');
        
        if (modalId) {
            // set the handlebars content of the modal if the modal
            const button = document.getElementById(`${modalId}-button`);
            const dataSet = button.getAttribute('data-modalcontent');
            const source = document.querySelector('#modalFormTemplate').innerHTML;

            const bookingTemplate = Handlebars.compile(source);

            const context = JSON.parse(dataSet);

            const formHtml = $(context.formHtml);
            const input = formHtml.find("input[name=FormId]");
            input.val(context.formId);
            context.formHtml = formHtml[0].outerHTML;
        
            const html = bookingTemplate(context);
        
            // get the element to set the new HTML into
            const destination = document.querySelector("#modalFormInner");
        
            // set the new HTML
            destination.innerHTML = html;

            // Set available dates when modal re-initialises with errors
            console.log(pageLoadModal, context);
            initialiseDropdowns(destination, context);
        }

        // launch modal
        const dialog = new A11yDialog(pageLoadModal);
        addBodyClass(dialog);
        dialog.show();
    }
}

const initModalButtons = function() {
    if ($('#virtualTourTemplate').length) {
        const source = document.querySelector('#virtualTourTemplate').innerHTML;

        tourTemplate = Handlebars.compile(source);

        $('.virtualTourTrigger').on('click', processTourModalContent);
    }

    if ($('#modalFormTemplate').length) {
        const source = document.querySelector('#modalFormTemplate').innerHTML;

        bookingTemplate = Handlebars.compile(source);

        $('.modalFormTrigger').on('click', processModalFormContent);    
    }
}

$(document).ready(function() {

    $('.modalContainer').each(function(){
        var id = $(this).attr('id'); // make sure your modalContainer element has a unique ID
        var container = document.getElementById(id)
        var dialog = new A11yDialog(container)
        
        addBodyClass(dialog);
    });

    handleShowModal();

    initModalButtons();
});

const initialiseDropdowns = function(target, context = null) {
    const dropdowns = $(target).find('.__select select');
    const datePickers = $(target).find('.__date input');

    dropdowns.each(function() {
        const dropdownId = $(this).parent();

        $(this).select2({
            selectionCssClass: 'select2-css',
            minimumResultsForSearch: Infinity,
            dropdownParent: dropdownId,
            width: "100%",
        });
    });

    flatpickr(datePickers, {
        altInput: true, // creates new visual input
        altFormat: "d/m/Y", // sets the format to be more friendly, i.e. Thursday 8th April 2021
        dateFormat: "Y-m-d", // sets the format to match server-side data i.e. 2021-04-08
        enable: availableDates,
        onChange: function(selectedDates) {
            setSlots(selectedDates);
        }
    });

    if (context) {
        if (context.isCallback) {
            $('#callback-time').show();
        } else {
            $('#callback-time').hide();
        }
        updateAvailableSlots(context.contentId);
    }    
}

const setSlots = (date) => {
    const slotDropdown = document.querySelector('#SlotId');

    // remove all select2 options
    const slotSelect2 = $('#SlotId');
    slotSelect2.empty().trigger('change');

    const formattedDate = new Date(date).getDate();

    // filter dates based on selected datepicker value
    let availableTimes = availableSlots.filter(slot => {
        const formattedSlot = new Date(slot.time).getDate();
        return formattedSlot == formattedDate;
    });
    
    // add select option for each available time
    availableTimes.forEach(slot => {
        const formattedTime = new Date(slot.time).toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit' });
        slotDropdown.add( new Option(formattedTime, slot.id));
    });

}

const updateAvailableSlots = async (contentId) => {
    const destination = document.querySelector("#modalFormInner");
    
    if (!contentId) {
        availableSlots = [];
        availableDates = [];
        initialiseDropdowns(destination);
        return;
    }

    let response = await fetch(`/api/availability/${contentId}`);

    if (response.ok) {
        availableSlots = [];
        availableDates = [];
        let json = await response.json();
        json.forEach(slot => {
            availableSlots.push({
                time: slot.startTime,
                id: slot.slotId
            });
            availableDates.push(slot.startTime);
        });
    } else {
        console.log(`HTTP-Error: ${response.status}`);
    }

    initialiseDropdowns(destination);
}

function processTourModalContent()
{
    var context = JSON.parse(this.dataset.modalcontent);

    const html = tourTemplate(context);

    // get the element to set the new HTML into
    const destination = document.querySelector("#virtualTourModalInner");

    // set the new HTML
    destination.innerHTML = html;
}

function processModalFormContent()
{
    var context = JSON.parse(this.dataset.modalcontent);

    // This is a bit convoluted - essentially the BE form is rendered once
    // (per node) but we need to post the exact form ID (button ID) 
    // to the server, so that we can auto-popup if there's validation errors 
    var formHtml = $(context.formHtml);
    var input = formHtml.find("input[name=FormId]");
    input.val(context.formId);
    context.formHtml = formHtml[0].outerHTML;

    const html = bookingTemplate(context);

    // get the element to set the new HTML into
    const destination = document.querySelector("#modalFormInner");

    // set the new HTML
    destination.innerHTML = html;

    initialiseDropdowns(destination, context);
	
	disableFormButtonsOnSubmit();
	
	if (destination.querySelectorAll('.g-recaptcha-submit').length > 0) {
		initialiseRecaptchaClicks();
	}
}

function disableFormButtonsOnSubmit() {
	const forms = $('form');

    forms.each(function() {
        const thisForm = $(this);
        const submitBtn = thisForm.find('input[type=submit], button[type=submit]');

        thisForm.submit(function() {
            submitBtn.each(function() {
                $(this).attr('disabled', true);
            })
        })
    });
}
