$("a.video, button.video").each(function () {
    const video = $(this);
    const thisVideoSrc = video.data('mfp-src');
    if (thisVideoSrc.includes('https://youtu.be')) {
        const newStr = thisVideoSrc.split('https://youtu.be/')[1];
        const newYoutubeUrl = 'https://www.youtube.com/watch?v=' + newStr;
        $(this).attr('data-mfp-src', newYoutubeUrl);
    }
    $(this).magnificPopup({
        type: "iframe",
        removalDelay: 160,
        preloader: !1,
        fixedContentPos: !1
    })
});