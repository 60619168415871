$('.videoblock, .videoblock-local').each(function() {
    const thisVideo = $(this);

    thisVideo.mousemove(function(e) {
        const circle = $(this).find('.cursor');
        const hoverCursor = $(this).find('.videoplay');

        circle.css({
            'left': e.pageX,
            'top': `${e.pageY - $(window).scrollTop()}px`,
        });

        hoverCursor.mouseenter(function() {
            circle.addClass('vis');
        });

        hoverCursor.mouseleave(function() {
            circle.removeClass('vis');
        })
    })
})