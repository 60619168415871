$(function () {
    // Default select with no search
    $('.costcalculator-search .__select select').each(function () {
        $(this).select2({
            selectionCssClass: 'select2-css',
            minimumResultsForSearch: Infinity,
            width: "100%",
        });
    });
});
