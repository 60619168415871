const locationButton = document.querySelectorAll('.useLocation');
const queryInput = document.getElementById('location-search-input');

function setLocation(position) {
    var latitude = position.coords.latitude;
    var longitude = position.coords.longitude;
    
    setLocationInput(latitude, longitude);

    $.get(`api/geolocation/postcode?latitude=${latitude}&longitude=${longitude}`, function(postcode) {
        setQueryInput(postcode)
    });
}

function setLocationInput(latitude, longitude) {
    const latInput = document.getElementById('latitude');
    const lngInput = document.getElementById('longitude'); 

    latInput.value = latitude;
    lngInput.value = longitude;
}

function setQueryInput(value) {
    queryInput.value = value;
}

locationButton.forEach(btn => {
    btn.addEventListener('click', function() {
        navigator.geolocation.getCurrentPosition(setLocation, function() {
            console.log(error);
        });
    })

    // If a query is typed, ignore the existing geolocation
    queryInput.addEventListener('keyup', function() {
        setLocationInput(null, null);
    });
});