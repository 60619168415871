/* searchtoggle */
$(function() {
    var searchToggle = $('.searchToggleTrigger');
    var searchBar = $('.searchBarElement');
    var searchBarFocusElement = $('.searchBarFocus');
    var wrapper = $('.wrapper');
    var searchBarHidden = true;

    searchToggle.click(function(event) {
        event.preventDefault();
        searchBarHidden = !searchBarHidden;
        $(this).toggleClass('__active');
        wrapper.toggleClass('__searchactive');
        searchBar.attr('aria-hidden',searchBarHidden);
        if (searchBarHidden) {
            searchBar.find('*[tabindex=0]').attr('tabindex', '-1');
            searchToggle.focus();
        } else {
            searchBar.find('*[tabindex=-1]').attr('tabindex', '0');
            searchBarFocusElement.focus();
        }
    });
});
