$('.videoMp4').each(function() {
    const videoId = $(this).data('mfp-src');
    $(this).magnificPopup({
        src: $.parseHTML(html),
        type: 'inline',
        callbacks: {
            open: function() {
                // Play video on open:
                if ($('.mfp-content').find('#'+videoId).length > 0) {
                    $('.mfp-content').find('#'+videoId).find('video')[0].play();
                }
            },
            close: function() {
                // Reset video on close:
                if ($('#'+videoId).length > 0) {
                    $('#'+videoId).find('video')[0].load();
                }
            }
        }
    });
});
