/* accordion */
$(function() {
    $('.Accordion').each(function(i, accordion) {
        var accordionTrigger = '.AccordionTrigger';
        var accordionContent = '.AccordionContent';
        var findAccordionTrigger = $(this).find(accordionTrigger);
        var findAccordionContent = $(this).find(accordionContent);

        // Apply ARIA and display values on load
        if (findAccordionTrigger.hasClass('__active')) {
            findAccordionTrigger.attr('aria-expanded', true);
            findAccordionContent.show();
        } else {
            findAccordionTrigger.attr('aria-expanded', false);
            findAccordionContent.hide();
        }

        // On click of accordion trigger
        findAccordionTrigger.click(function(e) {
            e.preventDefault();
            /* close an open accordion */
            if ($(this).hasClass('__active')) {
                $(this).attr('aria-expanded', false);
                $(this).removeClass('__active');
                $(this).siblings('.AccordionTrigger').removeClass('__active');
                $(this).closest('.Accordion').removeClass('__active');
                findAccordionContent.slideUp();
            } /* open a closed accordion and close open siblings without the AccordionManualClose class*/ else {
                $(this).attr('aria-expanded', true);
                $(this).parents('.Accordion').not('.AccordionManualClose').siblings().children('.AccordionTrigger').attr('aria-expanded', false);
                $(this).addClass('__active');
                $(this).siblings('.AccordionTrigger').addClass('__active');
                $(this).closest('.Accordion').addClass('__active');
                $(this).parents('.Accordion').not('.AccordionManualClose').siblings().children('.AccordionTrigger').removeClass('__active');
                findAccordionContent.slideDown();
                $(this).parents('.Accordion').not('.AccordionManualClose').siblings().children('.AccordionContent').slideUp();
                $(this).parents('.Accordion').not('.AccordionManualClose').siblings().removeClass('__active');
            }
        });
    });
});
