/* ScrollTo */
(function() {
    $.fn.scrollto = function(options) {
        var settings = $.extend(
            {
                trigger: '#ScrollTo',
                scroll_to: '.wrapper',
                speed: 1000,
                offset: 0
            },
            options
        );
        $(settings.trigger).click(function(event) {
            event.preventDefault();
            $('html, body').animate(
                {
                    scrollTop:
                        $(settings.scroll_to).offset().top + settings.offset
                },
                settings.speed
            );
        });
    };
})();

$('#ScrollTo').scrollto({
    trigger: '#ScrollTo',
    scroll_to: '.wrapper',
    speed: 1000
});
