// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

try {
    // load fonts
    WebFont.load({
        google: { // add &display=swap to end of ONLY END font family, not every single one, other it doesnt load those fonts after
            families: ['Inter:400,500,700&display=swap']
        },
        //,
        // typekit: {
        // id: 'XXXX'
        // },
        active: function() { // helps stop flash of unstyled text
            sessionStorage.fontsLoaded = true
        }
    });
} catch (error) {
    console.log("Failed to load web fonts", error);
}

// Load Libraries
require('flatpickr/dist/flatpickr.min');
require('magnific-popup/dist/jquery.magnific-popup.min');
require('select2/dist/js/select2.full.min');
require('@accessible360/accessible-slick/slick/slick.min');

// Load Modules
require('./modules/accordion');
require('./modules/animatein');
require('./modules/bannerVideo');
require('./modules/formInputs');
require('./modules/videoButtonReplacer');
require('./modules/videoPopup');
require('./modules/videoPopupMp4');
require('./modules/menu');
require('./modules/modals');
require('./modules/scrollTo');
require('./modules/searchToggle');
require('./modules/shareSocial');
require('./modules/sideMenu');
require('./modules/tabs');
require('./modules/useLocation');
require('./modules/videoCursor');
require('./modules/costCalculator');
