/* Radiobuttons */
$(document).on('change', '.__radio input', function () {
    if ($(this).is(':checked')) {
        $(this)
            .closest('.__radio')
            .find('.input_wrap > label')
            .removeClass('__checked');
        $(this)
            .parent()
            .addClass('__checked');
    }
});
$('.__radio input:checked')
    .parent()
    .addClass('__checked');

/* Checkboxes */
$(document).on('change', '.__checkbox input', function () {
    if ($(this).is(':checked')) {
        $(this)
            .parent()
            .addClass('__checked');
    } else {
        $(this)
            .parent()
            .removeClass('__checked');
    }
});
$('.__checkbox input:checked')
    .parent()
    .addClass('__checked');

/* Focus state */
$(document).ready(function () {
    $('.field_wrap.__radio input').focus(function () {
        $(this)
            .closest('label')
            .addClass('__focused');
    }).blur(function () {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
    $('.field_wrap.__checkbox input').focus(function () {
        $(this)
            .closest('label')
            .addClass('__focused');
    }).blur(function () {
        $(this)
            .closest('label')
            .removeClass('__focused');
    });
});

/* Reset checkboxes and dropdown selection */
$('.__reset').click(function () {
    $('.__radio label').removeClass('__checked');
    $('.__checkbox label').removeClass('__checked');
    $('.__select .select2-selection__rendered').empty().addClass('__cleared');
    $('.__selectwithimages .select2-selection__rendered').empty().addClass('__cleared');
})

// disable submit buttons after submitting
$(document).ready(function() {
    disableFormButtonsOnSubmit();
})

function disableFormButtonsOnSubmit() {
	const forms = $('form');

    forms.each(function() {
        const thisForm = $(this);
        const submitBtn = thisForm.find('input[type=submit], button[type=submit]');

        thisForm.submit(function() {
            submitBtn.each(function() {
                $(this).attr('disabled', true);
            })
        })
    });
}